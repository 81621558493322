import React from "react";
import FileUpload from "../Components/Upload";

function Upload() {
  return (
    <div>
      <FileUpload />
    </div>
  );
}

export default Upload;
